<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <!--搜索与添加区域-->
      <el-row :gutter="30">
        <el-col :span="4">
          <el-button type="primary" @click="AdddateGoods" v-if="is_add">添加时间</el-button>
        </el-col>
      </el-row>

      <!--   用户列表区域   -->
      <el-table :data="goodslist"  border stripe v-loading="loading">
        <el-table-column type="index" ></el-table-column>
        <el-table-column label="开始时间" prop="gad_start_date"></el-table-column>
        <el-table-column label="结束时间" prop="gad_end_date"></el-table-column>
        <el-table-column label="库存" prop="gad_stock"></el-table-column>
        <el-table-column label="添加时间" prop="add_date"></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <!-- 编辑 -->
            <el-button type="primary" size="mini" v-if="is_edit"
                       @click="editgoods(scope.row.gad_id)">编辑</el-button>
            <!--删除-->
            <el-button type="danger" size="mini" autofocus v-if="is_del"
                       @click="removeGoodsById(scope.row.gad_id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">商品名称：</span>
          <span class="text">{{goods.g_name}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">规格名称：</span>
          <span class="text">{{goods.ga_name}}</span>
        </el-col>

      </el-row>

    </el-card>
    <!-- 添加质量等级对话框-->
    <el-dialog
        title="添加质量等级" :visible.sync="addqualitylevelDialogVisible" width="50%" @close="addqualitylevelDialogClose">
      <!--    添加质量等级的表单    -->
      <el-form :model="adddategoodsForm" :rules="adddategoodsFormRules" ref="adddategoodsFormRef" label-width="150px">
        <el-form-item label="开始时间：" prop="gad_start_date">
          <el-date-picker v-model="adddategoodsForm.gad_start_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" prop="gad_end_date">
          <el-date-picker v-model="adddategoodsForm.gad_end_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addqualitylevelDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="adddategoods">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑质量等级对话框-->
    <el-dialog
        title="添加质量等级" :visible.sync="editqualitylevelDialogVisible" width="50%" @close="addqualitylevelDialogClose">
      <!--    添加质量等级的表单    -->
      <el-form :model="editdategoodsForm" :rules="editdategoodsFormRules" ref="editdategoodsFormRef" label-width="150px">
        <el-form-item label="开始时间：" prop="gad_start_date">
          <el-date-picker v-model="editdategoodsForm.gad_start_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" prop="gad_end_date">
          <el-date-picker v-model="editdategoodsForm.gad_end_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editqualitylevelDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editqualitylevel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return{
      goods:{},
      goodslist:[],
      //控制添加对话框的显示和隐藏
      addqualitylevelDialogVisible:false,
      //控制编辑对话框的显示和隐藏
      editqualitylevelDialogVisible:false,
      adddategoodsForm:{
        ga_id:'',
        gad_start_date:'',
        gad_end_date:''
      },
      adddategoodsFormRules: {
        gad_start_date:[
          {required: true, message: '请选择开始时间', trigger: 'change'}
        ],
        gad_end_date:[
          {required: true, message: '请选择结束时间', trigger: 'change'}
        ],
      },
      editdategoodsForm:{
        ga_id:'',
        gad_start_date:'',
        gad_end_date:''
      },
      editdategoodsFormRules:{
        gad_start_date:[
          {required: true, message: '请选择开始时间', trigger: 'change'}
        ],
            gad_end_date:[
          {required: true, message: '请选择结束时间', trigger: 'change'}
        ],
      },
      is_add:false

    }
  },
  created() {
    this.getGoodsList()
    this.getGoods()
    this.getWarehouseJurisdiction()
  },
  methods: {
    //获取权限接口
    async getWarehouseJurisdiction(){
      this.loading=true
      let str = this.$route.path;
      let activePath = str.substr(1);
      console.log(activePath)
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.WarehouseJurisdiction=res.data
      this.WarehouseJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }
      })
    },
    //
    async getGoods(){
      this.loading=true
      const {data: res} = await this.$http.get(`goods/find/${this.$route.query.editid}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.goods=res.data
      this.loading=false
    },
    async getGoodsList(){
      const {data: res} = await this.$http.get('goodsattrdate/getList',
          {params:{ga_id:this.$route.query.editid}})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值给catelist
      this.goodslist = res.data
      console.log(res.data)
    },
    //监听添加对话框的关闭事件
    addqualitylevelDialogClose(){
      this.adddategoodsForm.ga_start_date=''
      this.adddategoodsForm.ga_end_date=''
      this.$refs.adddategoodsFormRef.resetFields()
    },
    //点击弹出
    AdddateGoods(){
      this.addqualitylevelDialogVisible=true
    },
    //点击提交
    async adddategoods(){
      this.adddategoodsForm.ga_id=this.$route.query.editid
      const {data:res} = await this.$http.post('goodsattrdate/add',this.adddategoodsForm)
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.addqualitylevelDialogVisible=false
      this.getGoodsList()
    },


     async editgoods(id){
      const {data:res} = await this.$http.get(`goodsattrdate/find/${id}`)
      if (res.code!=200) return this.$message.error(res.msg)
      this.editdategoodsForm=res.data
      console.log(this.editGoodsForm)
      this.editqualitylevelDialogVisible=true
    },
    async editqualitylevel(){
      const {data:res} = await this.$http.post('goodsattrdate/edit',this.editdategoodsForm)
      if (res.code!=200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.editqualitylevelDialogVisible=false
      this.getGoodsList()
    },
    //删除 qualitylevel/del/12
    async removeGoodsById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除该保质期, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`goodsattrdate/del/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getGoodsList()

    },

  }
}
</script>
<style lang="less" scoped>
.title{
  color: #415161;
  font-size: 13px;
}
.text{
  color: #415161;
  font-size: 13px;
}
</style>
